import React from 'react'

const Header = () => {
  return (
    <div
        className='header'
    >
        <h1>
            Lori's 60th Era
        </h1>
    </div>
  )
}

export default Header